// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/render/project/src/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-recipe-js": () => import("/opt/render/project/src/src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("/opt/render/project/src/src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-opskrifter-js": () => import("/opt/render/project/src/src/pages/opskrifter.js" /* webpackChunkName: "component---src-pages-opskrifter-js" */),
  "component---src-pages-index-mdx": () => import("/opt/render/project/src/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

